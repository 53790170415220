import React, { useState } from 'react'
import { useQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Button } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { PlusCircle, QrCode, Image, Key, Search } from 'react-bootstrap-icons'
import SubjectGroupSubjectMetadata from './SubjectGroupSubjectMetadata'
import SubjectGroupSubjectImaqeQuixId from './SubjectGroupSubjectImaqeQuixId'
import SubjectGroupSubjectCustomFields from './SubjectGroupSubjectCustomFields'
import { loggedInUserVar } from '../../libs/apollo'
import { useAWSS3 } from '../../libs/aws'
import { useImagequixEnabled } from '../../libs/imagequix'
import { useParams } from 'react-router-dom'
import Loading from '../common/Loading'
import { useDownloadAndDeleteFile } from '../../libs/downloadAndDeleteFile'

const SubjectSubjectGroupsTable = (props) => {
  const { subjectId } = props
  const { contact } = useParams()
  let hasMoreSubjectGroups = true
  let subjectGroupsCursor
  const awsS3 = useAWSS3()
  const iqEnabled = useImagequixEnabled()
  const [showMetadataModal, setShowMetadataModal] = useState(false)
  const [showIqIdModal, setShowIqIdModal] = useState(false)
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false)
  const [sgsId, setSgsId] = useState()
  const [sgsMetadata, setSgsMetadata] = useState()
  const [iqId, setIqId] = useState()
  const [sgsNode, setSgsNode] = useState()
  const history = useHistory()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canUpdate = [
    'Scheduling Analyst',
    'Administrator',
    'Scheduling Manager',
  ].includes(loggedInUser?.permissions?.group)
  const subject = ['Subject'].includes(loggedInUser?.permissions?.group)

  const tableColumns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Bookable Between',
      accessor: 'bookableBetween',
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: 'status',
    },
  ]
  if (!contact && !subject) {
    tableColumns.push({
      id: 'metadata',
      Header: 'Metadata',
      accessor: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="link"
              onClick={() => {
                setSgsId(row.sgsId)
                setSgsMetadata(row.sgsMetadata)
                toggleMetadataModal()
              }}
            >
              <Search />
            </Button>
          </div>
        )
      },
    })
  }
  if (iqEnabled) {
    tableColumns.splice(2, 0, {
      Header: (
        <>
          <span className="ml-2">Gallery</span>
        </>
      ),
      id: 'imagequixId',
      accessor: (row) => {
        return (
          <>
            {canUpdate && (
              <Button
                variant="link"
                onClick={() => {
                  setSgsId(row.sgsId)
                  setIqId(row?.imagequixSubject?.imagequixId)
                  toggleIqIdModal()
                }}
              >
                <Search />
              </Button>
            )}
            {row?.imagequixSubject?.imagequixId && (
              <>
                <Button
                  variant="link"
                  onClick={() => {
                    window.open(
                      `https://vando.imagequix.com/s${row.imagequixSubject.imagequixId}`,
                      '_blank'
                    )
                  }}
                >
                  Gallery
                </Button>
              </>
            )}
          </>
        )
      },
    })
  }
  const {
    error: subjectGroupsQueryError,
    data: subjectGroupsQueryData,
    fetchMore: subjectGroupsQueryFetchMore,
  } = useQuery(
    gql`
      query SubjectGroupsQuery($subjectId: ID, $cursor: String) {
        subjectGroupSubjects(
          subject: $subjectId
          first: 20
          after: $cursor
          orderBy: "-subject_group__end_date_time"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              imagequixSubject {
                imagequixId
                idQrCodeFile {
                  fileName
                  displayName
                }
                galleryQrCodeFile {
                  fileName
                  displayName
                }
              }
              metadata
              customOne
              customTwo
              customThree
              status
              customFour
              customFive
              subjectGroup {
                name
                id
                startDateTime
                endDateTime
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        subjectId,
      },
    }
  )
  const toggleMetadataModal = () => {
    if (showMetadataModal) {
      setSgsId(null)
      setSgsMetadata(null)
    }
    setShowMetadataModal((prevState) => !prevState)
  }
  const toggleIqIdModal = () => {
    if (showIqIdModal) {
      setSgsId(null)
      setIqId(null)
    }
    setShowIqIdModal((prevState) => !prevState)
  }

  const toggleCustomFieldModal = (sgs = null) => {
    setSgsNode(sgs)
    setShowCustomFieldModal((prevState) => !prevState)
  }

  const handleSubjectGroupsQueryData = () => {
    hasMoreSubjectGroups =
      subjectGroupsQueryData.subjectGroupSubjects.pageInfo.hasNextPage
    if (subjectGroupsQueryData.subjectGroupSubjects.pageInfo.endCursor) {
      subjectGroupsCursor =
        subjectGroupsQueryData.subjectGroupSubjects.pageInfo.endCursor
    }
    const subjectGroupTableRows = []
    subjectGroupsQueryData.subjectGroupSubjects.edges.forEach(
      (subjectGroup) => {
        const startZone = DateTime.fromISO(
          subjectGroup.node.subjectGroup.startDateTime
        ).toFormat('LLL dd, yyyy')
        const endZone = DateTime.fromISO(
          subjectGroup.node.subjectGroup.endDateTime
        ).toFormat('LLL dd, yyyy')
        subjectGroupTableRows.push({
          node: subjectGroup.node,
          id: subjectGroup.node.subjectGroup.id,
          imagequixSubject: subjectGroup.node?.imagequixSubject,
          sgsId: subjectGroup.node.id,
          sgsMetadata: subjectGroup.node.metadata,
          name: subjectGroup.node.subjectGroup.name,
          status: subjectGroup.node.status,
          bookableBetween: `${startZone} - ${endZone}`,
        })
      }
    )
    return subjectGroupTableRows
  }
  const fetchMoreSubjectGroups = () => {
    subjectGroupsQueryFetchMore({
      variables: {
        subjectId,
        cursor: subjectGroupsCursor,
      },
    })
  }
  const onTdClick = (cell) => {
    if (!subject) {
      if (loggedInUser.permissions.isOrgContact) {
        history.push(`/subject-group/${cell.row.original.id}/contact`)
      } else {
        history.push(`/subject-group/${cell.row.original.id}`)
      }
    }
  }
  const onTdClicks = {
    name: onTdClick,
    bookableBetween: onTdClick,
    status: onTdClick,
  }
  if (!subjectGroupsQueryData || !awsS3?.client)
    return (
      <Row>
        <Col>
          <Loading />
        </Col>
      </Row>
    )
  if (subjectGroupsQueryError) return <>Error loading subject groups</>
  const subjectGroups = handleSubjectGroupsQueryData()
  return (
    <>
      <div>
        <Row>
          <Col md={12}>
            <div id="subjectGroupsCollapse">
              {subjectGroups && (
                <SortableInfiniteTable
                  tableData={subjectGroups}
                  loadingMessage="Loading Subject Groups..."
                  tableColumns={tableColumns}
                  fetchMoreTableData={fetchMoreSubjectGroups}
                  hasMoreTableData={hasMoreSubjectGroups}
                  onTdClicks={onTdClicks}
                  tableHeight={500}
                  rowPointer={!subject}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      <SubjectGroupSubjectMetadata
        sgsId={sgsId}
        sgsMetadata={sgsMetadata}
        showModal={showMetadataModal}
        refetch={'SubjectGroupsQuery'}
        toggleModal={toggleMetadataModal}
      />
      <SubjectGroupSubjectImaqeQuixId
        sgsId={sgsId}
        imagequixId={iqId}
        showModal={showIqIdModal}
        refetch={'SubjectGroupsQuery'}
        toggleModal={toggleIqIdModal}
      />
      <SubjectGroupSubjectCustomFields
        sgs={sgsNode}
        showModal={showCustomFieldModal}
        refetch={'SubjectGroupsQuery'}
        toggleModal={toggleCustomFieldModal}
      />
    </>
  )
}

export default SubjectSubjectGroupsTable
