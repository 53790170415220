import React, { useState, useEffect } from 'react'
import { useQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Tab, Tabs } from 'react-bootstrap'
import { loggedInUserVar } from '../../libs/apollo'
import SubjectSessionsTable from './SubjectSessionsTable'
import { Camera, People, Person } from 'react-bootstrap-icons'
import SubjectSubjectGroupsTable from '../subject/SubjectSubjectGroupsTable'

const AllSubjectSessions = () => {
  let managedSubjects = []
  let subjectId
  const user = useReactiveVar(loggedInUserVar)
  const userId = user?.id
  const [currentSubject, setCurrentSubject] = useState('')
  const [activeTab, setActiveTab] = useState('sessions')
  const { error, data } = useQuery(
    gql`
      query SubjectsChildrenQuery($gaiaUserId: ID!) {
        gaiaUser(id: $gaiaUserId) {
          id
          fullName
          lastName
          subject {
            id
            subjectGroups {
              nodeCount
            }
            children {
              edges {
                node {
                  id
                  subjectGroups {
                    nodeCount
                  }
                  gaiaUser {
                    fullName
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        gaiaUserId: userId,
      },
      errorPolicy: 'all',
    }
  )

  const handleQueryData = () => {
    let managedSubjects = []
    if (data.gaiaUser.subject) {
      subjectId = data.gaiaUser.subject.id
      managedSubjects.push({
        id: data.gaiaUser.subject.id,
        name: data.gaiaUser.fullName,
        subjectGroupCount: data.gaiaUser.subject.subjectGroups.nodeCount,
      })
    }

    data.gaiaUser.subject.children.edges.forEach((subject) => {
      managedSubjects.push({
        id: subject.node.id,
        name: subject.node.gaiaUser.fullName,
        subjectGroupCount: subject.node.subjectGroups.nodeCount,
      })
    })
    return managedSubjects
  }

  useEffect(() => {
    if (managedSubjects.length > 0) {
      setCurrentSubject(managedSubjects[0])
    }
  }, [])

  const handleClick = (e) => {
    setCurrentSubject(managedSubjects.filter((s) => s.id === e)[0])
  }

  if (!data) return <></>
  if (error) return <p>Error loading sessions</p>
  managedSubjects = handleQueryData()
  console.log(managedSubjects)
  return (
    <>
      <div>
        {managedSubjects.length > 1 ? (
          <div className="mt-4">
            <Tabs
              defaultActiveKey={managedSubjects[0].id}
              className="mb-3"
              onSelect={handleClick}
            >
              {managedSubjects.map((subject) => {
                const currentSubject_ = currentSubject
                  ? currentSubject
                  : managedSubjects[0]
                return (
                  <Tab
                    key={subject.id}
                    eventKey={subject.id}
                    title={
                      <b>
                        <Person className="mr-2" />
                        {subject.name ? <>{subject.name}</> : 'Sessions'}
                      </b>
                    }
                  >
                    <>
                      {currentSubject_.subjectGroupCount > 0 && (
                        <Tabs
                          defaultActiveKey={activeTab}
                          className="mb-3"
                          onSelect={setActiveTab}
                        >
                          <Tab
                            key={'sessions'}
                            eventKey={'sessions'}
                            title={
                              <b>
                                <Camera className="mr-2" />
                                Sessions
                              </b>
                            }
                          >
                            <SubjectSessionsTable
                              hideName
                              subjectId={currentSubject_.id}
                              relation={'me'}
                            />
                          </Tab>
                          <Tab
                            key={'groups'}
                            eventKey={'groups'}
                            title={
                              <b>
                                <People className="mr-2" />
                                Groups
                              </b>
                            }
                          >
                            <SubjectSubjectGroupsTable
                              subjectId={currentSubject_.id}
                            />
                          </Tab>
                        </Tabs>
                      )}
                      {currentSubject_.subjectGroupCount === 0 && (
                        <SubjectSessionsTable
                          hideName
                          subjectId={currentSubject_.id}
                          relation={'child'}
                        />
                      )}
                    </>
                  </Tab>
                )
              })}
            </Tabs>
          </div>
        ) : (
          <>
            {subjectId ? (
              <Row className="mt-3 mb-3">
                <Col>
                  {managedSubjects[0].subjectGroupCount > 0 && (
                    <Tabs
                      defaultActiveKey={activeTab}
                      className="mb-3"
                      onSelect={setActiveTab}
                    >
                      <Tab
                        key={'sessions'}
                        eventKey={'sessions'}
                        title={
                          <b>
                            <Camera className="mr-2" />
                            Sessions
                          </b>
                        }
                      >
                        <SubjectSessionsTable
                          hideName
                          subjectId={subjectId}
                          relation={'me'}
                        />
                      </Tab>
                      <Tab
                        key={'groups'}
                        eventKey={'groups'}
                        title={
                          <b>
                            <People className="mr-2" />
                            Groups
                          </b>
                        }
                      >
                        <SubjectSubjectGroupsTable subjectId={subjectId} />
                      </Tab>
                    </Tabs>
                  )}
                  {!managedSubjects[0].subjectGroupCount > 0 && (
                    <SubjectSessionsTable
                      subjectId={subjectId}
                      relation={'me'}
                    />
                  )}
                </Col>
              </Row>
            ) : (
              <>
                {managedSubjects[0].subjectGroupCount > 0 && (
                  <Tabs
                    defaultActiveKey={activeTab}
                    className="mb-3"
                    onSelect={setActiveTab}
                  >
                    <Tab
                      key={'sessions'}
                      eventKey={'sessions'}
                      title={
                        <b>
                          <Camera className="mr-2" />
                          Sessions
                        </b>
                      }
                    >
                      <SubjectSessionsTable
                        subjectId={
                          currentSubject
                            ? currentSubject.id
                            : managedSubjects[0].id
                        }
                        relation={'me'}
                      />
                    </Tab>
                    <Tab
                      key={'groups'}
                      eventKey={'groups'}
                      title={
                        <b>
                          <People className="mr-2" />
                          Groups
                        </b>
                      }
                    >
                      <SubjectSessionsTable
                        subjectId={
                          currentSubject
                            ? currentSubject.id
                            : managedSubjects[0].id
                        }
                        relation={'me'}
                      />
                    </Tab>
                  </Tabs>
                )}
                {!managedSubjects[0].subjectGroupCount > 0 && (
                  <SubjectSessionsTable
                    subjectId={
                      currentSubject ? currentSubject.id : managedSubjects[0].id
                    }
                    relation={'me'}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default AllSubjectSessions
